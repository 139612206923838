
import Highcharts from 'highcharts';
import {page} from "../page-ui";


export default function create_exam_prep_chart(placement, records, choices) {
    try {
        if (!records) {
            if (page.charts.examp_prep) {
                page.charts.examp_prep.hideLoading();
                page.charts.examp_prep.series[0].setData([]);
            } else {
                dk(`#${placement}`).innerHTML = "Mangler data";
            }
        }
    
        const data = {};
        records.map(record => data[record.verdi] = record.antall);
    
        if (!page.charts.examp_prep) {
            page.charts.examp_prep = Highcharts.chart(placement, {
                chart: {
                    type: 'column'
                },
                credits: false,
                title: {
                    text: null,
                },
                pane: {
                    size: '80%'
                },
                xAxis: {
                    categories: choices,
                    crosshair: true
                },
                yAxis: {
                    min: 0
                },
            
                series: [
                    {
                        name: 'opplæring',
                        data: choices.map(k => (data[k] || 0))
                    }
                ]
            
            });
        } else {
            page.charts.examp_prep.hideLoading();
            page.charts.examp_prep.series[0].setData(choices.map(k => (data[k] || 0)));
        }
    } catch (e) {
        dk.error("Exception happended during create_exam_prep_chart:" + e);
    }
}
