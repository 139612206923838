import dk from "@norsktest/dkdj";

class FilterPanel extends dk.panel.PanelWidget {
    constructor(...args) {
        super({
            direction: 'left',
            title: 'Filter',
            dataset: null,
        }, ...args);
    }
    construct_panel_body(body) {
        const self = this;
        const filterbox = dk.$('<div/>');
        body.append(filterbox);

        this.datafilter = dk.filter.DataFilter.create_on(filterbox, {
            structure: {
                content: {}
            },
            dataset: self.dataset,
            filters: {
                 tctr: {
                     label: 'Testsenter',
                     construct_filter: function (location, filter) {
                         this.widget = location.append(`
                            <div class="tctr-filter-box" style="display:flex">
                                <input type="search" name="tctr" class="form-control"
                                       placeholder="autnr"
                                       style="border-top-right-radius: 0;border-bottom-right-radius: 0;border-right:none">
                                <button class="btn"
                                        style="border-top-left-radius: 0;border-bottom-left-radius: 0;border-color:#ccc">
                                    &rsaquo;
                                </button>
                            </div>
                         `);

                         dk.$(this.widget).find('input[type=search]').on('change', function () {
                             filter.value = dk.$(this).val();
                             dk.trigger(filter, 'change', filter);
                         });
                     }
                 }
            }
        });
        return body;
    }
}


const boolfmt = dk.format.bool(
    '<dk-icon value="check:fw" style="color:green"></dk-icon>',
    '<dk-icon value="minus-circle:fw" style="color:red"></dk-icon>'
)

export class TestleaderList extends dk.ResultSet {

    constructor(...args) {
        const props = {};
        Object.assign(props, ...args);
        super({
            dataset: dk.data.Data.create({
                pagesize: 100,
                datasource: props.tl_datasource
            }),
            command_buttons: {
                add: {
                    show: true,
                    url: '../add-tl/'
                },
                csv: false
            }
        }, props);
    }

    construct_table(location, downloadwidget, ds) {
        const self = this;
        return dk.DataTable.create_on(location, {
            table_data: ds,
            columns: {
                id: {},
                username: {
                    label: 'Brukernavn',
                    format: function (val) {
                        return `<a href="/profile/${val}/">${val}</a>`;
                    }
                },
                first_name: {label: 'Fornavn'},
                last_name: {label: 'Etternavn'},
                autnr: {},
                tcname: {label: 'Testsenter'},
            }

        })
    }
}

export class TestleaderListKKN extends TestleaderList {
    constructor(...args) {
        super({
            command_buttons: {
                add: {
                    show: true,
                    url: 'add-tl/'
                },
                csv: {
                    show: true,
                }
            }
        }, ...args);
    }

    construct_filter(location, dataset) {
        return FilterPanel.create(location, {
            dataset: dataset
        });
    }

    construct_table(location, downloadwidget, ds) {
        const self = this;
        return dk.DataTable.create_on(location, {
            table_data: ds,
            columns: {
                id: {},
                username: {
                    label: 'Brukernavn',
                    format: function (val) {
                        return `<a href="/profile/${val}/">${val}</a>`;
                    }
                },
                first_name: {label: 'Fornavn'},
                last_name: {label: 'Etternavn'},
                autnr: {
                    format: function (autnr, rec) {
                        return `<a href="/kkn/tctr/${autnr}/">${autnr}</a>`
                    }
                },
                tcname: {label: 'Testsenter'},
            }

        })
    }
}


class AdminFilterPanel extends dk.panel.PanelWidget {
    constructor(...args) {
        super({
            direction: 'left',
            title: 'Filter',
            dataset: null,
        }, ...args);
    }
    construct_panel_body(body) {
        const self = this;
        const filterbox = dk.$('<div/>');
        body.append(filterbox);

        this.datafilter = dk.filter.DataFilter.create_on(filterbox, {
            structure: {
                content: {}
            },
            dataset: self.dataset,
            filters: {
                tctr: {
                    label: 'Testsenter',
                    select_multiple: true,
                }
            }
        });
        return body;
    }
}
export class AdminTestleaderList extends dk.ResultSet {

    constructor(...args) {
        const props = {};
        Object.assign(props, ...args);
        super({
            dataset: dk.data.Data.create({
                pagesize: 100,
                datasource: props.tl_datasource
            }),
            command_buttons: {
                add: {
                    show: true,
                    url: '../add-tl/'
                },
                csv: false
            }
        }, props);
    }

    construct_filter(location, dataset) {
        return AdminFilterPanel.create(location, {
            dataset: dataset
        });
    }

    construct_table(location, downloadwidget, ds) {
        const self = this;
        return dk.DataTable.create_on(location, {
            table_data: ds,
            columns: {
                id: {},
                username: {
                    label: 'Brukernavn',
                    format: function (val) {
                        return `<a href="/profile/${val}/">${val}</a>`;
                    }
                },
                first_name: {label: 'Fornavn'},
                last_name: {label: 'Etternavn'},
                autnr: {},
                tcname: {label: 'Testsenter'},
            }

        })
    }
}
