
import Highcharts from 'highcharts';
import {page} from "../page-ui";


export default function create_results_chart(placement, records) {
    try {
        const result_histogram = records.map(r => r.percentage_score).sort((a, b) => b < a);
        // console.log("RESULTS:CHART:", result_histogram);
    
        if (!page.charts.results) {
            if (result_histogram.length > 0) {
                page.charts.results = Highcharts.chart(placement, {
                    title: {
                        text: 'resultat (%)'
                    },
                    credits: false,
                    xAxis: [
                        {
                            title: {text: 'data'},
                            alignTicks: false
                        }, {
                            title: {text: 'bin-verdi (histogram)'},
                            alignTicks: false,
                            opposite: true
                        }
                    ],
                
                    yAxis: [
                        {
                            title: {text: '%-score (data)'}
                        }, {
                            title: {text: 'antall (histogram)'},
                            opposite: true
                        }
                    ],
                
                    series: [
                        {
                            name: 'Histogram',
                            type: 'histogram',
                            binsNumber: Math.min(17, Math.sqrt(records.length)),
                            xAxis: 1,
                            yAxis: 1,
                            baseSeries: 's1',
                            zIndex: -1
                        }, {
                            name: 'Data',
                            type: 'scatter',
                            data: result_histogram,
                            id: 's1',
                            marker: {
                                radius: 1.5
                            }
                        }
                    ]
                });
            }
        } else {
            page.charts.results.hideLoading();
            page.charts.results.series[0].update({binsNumber: Math.min(17, Math.sqrt(records.length))});
            page.charts.results.series[1].setData(result_histogram);
        }
    } catch (e) {
        dk.error("Exception happended during create_results_chart:" + e);
    }
}
