import dk from "@norsktest/dkdj";

class FilterPanel extends dk.panel.PanelWidget {
    constructor(...args) {
        super({
            direction: 'left',
            title: 'Filter',
            dataset: null,
        }, ...args);
    }
    construct_panel_body(body) {
        const self = this;
        const filterbox = dk.$('<div/>');
        body.append(filterbox);

        this.datafilter = dk.filter.DataFilter.create_on(filterbox, {
            structure: {
                content: {}
            },
            dataset: self.dataset,
            filters: {
                 bool_filters: {
                     label: null,
                     select_multiple: true,
                     values: {
                         skole: 'Skole',
                         afrfu: 'Finaut',
                         bfp: 'Båt',
                         dk: 'Datakortet',
                         solarium: 'Solarium'
                     }
                 }
            }
        });
        return body;
    }
}


const boolfmt = dk.format.bool(
    '<dk-icon value="check:fw" style="color:green"></dk-icon>',
    '<dk-icon value="minus-circle:fw" style="color:red"></dk-icon>'
)

export class TestcenterList extends dk.ResultSet {
    constructor(...args) {
        const props = {};
        Object.assign(props, ...args);

        super({
            dataset: dk.data.Data.create({
                pagesize: 100,
                datasource: props.tctr_datasource
            }),
            command_buttons: {
                add: false,
                csv: false
            }
        }, props);
    }

    construct_filter(location, dataset) {
        return FilterPanel.create(location, {
            dataset: dataset
        });
    }

    construct_table(location, downloadwidget, ds) {
        const self = this;
        return dk.DataTable.create_on(location, {
            table_data: ds,
            columns: {
                autnr: {
                    format: function (val, rec) {
                        return `<a href="${self.adminsite}/admin/dktestcenter/testcenter/${rec.id}/change/">${val} (admin)</a>`
                    }
                },
                name: {
                    format: function (val, rec) {
                        return `<a href="/kkn/tctr/${rec.autnr}/">${val}</a>`
                    }
                },
                skole: {format: boolfmt},
                afrfu: {format: boolfmt},
                bfp: {format: boolfmt},
                dk: {format: boolfmt},
                solarium: {format: boolfmt},
            }

        })
    }
}


export class AdminTestcenterList extends dk.ResultSet {

    constructor(...args) {
        const props = {};
        Object.assign(props, ...args);
        super({
            dataset: dk.data.Data.create({
                pagesize: 100,
                datasource: props.tctr_datasource
            }),
            command_buttons: {
                add: false,
                csv: false
            }
        }, props);
    }

    construct_table(location, downloadwidget, ds) {
        const self = this;
        return dk.DataTable.create_on(location, {
            table_data: ds,
            columns: {
                name: {
                    format: function (val, rec) {
                        return `<a href="/custadm/tctr/${rec.autnr}/">${val}</a>`
                    }
                },
                autnr: {
                    format: function (val, rec) {
                        return `<a href="/tctr/${rec.autnr}/">${val}</a>`
                    }
                },
            }
        })
    }
}
