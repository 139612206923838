
import dk from "@norsktest/dkdj";


export class MainMenu extends dk.Widget {
    constructor(...args) {
        super({

        }, ...args);
    }

    construct() {
        this.page_name = dk.$('body').attr('name');
        this.sections = this.widget('section.toolbar-section');
        this.menus = [];
        this.widget('[type=menu]').each((i, item) => {
            this.menus.push(item.getAttribute('menu'));
        });
        this.validate_menus();
 
        $('#page-breadcrumbs').html($('#breadcrumb-def').html());
    }

    get selected() {
        return this.widget(`[type=menu][menu=${this.page_name}]`);
    }

    select(name) {
        this.page_name = name;
        this.draw();
    }

    validate_menus() {
        if (!this.page_name) {
            dk.error(`
            You haven't defined a menu association...
            Valid options are: ${this.menus}.
            set menu choice by {% block menu_name %}<valid-option-here>{% endblock %}
            `);
            return;
        }
        if (this.selected.length === 0) {
            dk.error(`
            Unknown menu ${this.page_name}. Valid options are: ${this.menus}.

            set menu choice by {% templatetag openblock %} block menu_name {% templatetag closeblock %}<valid-option-here>{% templatetag openblock %} endblock {% templatetag closeblock %}
            `);
            return;
        }
    }

    draw() {
        this.widget('.selected').removeClass('selected');
        this.selected.addClass('selected');
    }
}
