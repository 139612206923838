import ds from "./datasources";
import {page} from "./page-ui";
import {MainMenu} from "./menu";
import create_exam_prep_chart from "./charts/exam-prep-chart";
import create_gender_distribution_chart from "./charts/gender-distribution-chart";
import create_time_used_chart from "./charts/create-time-used-chart";
import create_results_chart from "./charts/results-chart";
import create_pass_fail_chart from "./charts/pass-fail-chart";
import create_results_per_tctr_chart from "./charts/results-per-tctr-graph";


function set_month(m) {
    page.filter.month = m.idtag();
    page.data.month = m;
    // page.state.set_item('month', m.idtag());
    ds.set_filter(page.filter);
}

function set_autnr(autnr) {
    page.filter.autnr = autnr;
    page.data.autnr = autnr;
    // page.state.set_item('autnr', autnr);
    ds.set_filter(page.filter);
}

function create_tctr_filter(records) {
    const tctr_count = {};
    records.forEach(r => tctr_count[r.autnr] = []);
    records.forEach(r => tctr_count[r.autnr].push(r));
    // console.log('tctr-count:', tctr_count);
    const tctr_filter = $('#tctr-filter');
    tctr_filter.empty();
    Object.keys(tctr_count).sort().forEach(k => {
        tctr_filter.append($(`<div class="tctr-filter" data-autnr="${k}">${k}</div>`));
    });
}

dk.$(function () {
    
    dk.globals.menu = MainMenu.create_on('#top-level-menu');
    
    
    // const widgets = page.ui.widgets;

    // dk.on(ds.result_ds, 'fetch-data', (ds, page) => $('#result-count').text(page.records.length));

    // dk.on(ds.examprep_ds, 'fetch-data', function (ds, page) {
    //     dk.debug("EXAMPREP:RECORDS:", page.records);
    //     create_exam_prep_chart(
    //         'exam-prep',
    //         page.records,
    //         ["nettkurs", "klasseromskurs", "selvstudie", "annet"]  // correct order
    //     );
    // });

    // dk.on(ds.gender_ds, 'fetch-data', function (ds, data) {
    //     const records = data.records;
    //     // console.info("RECORDS:GENDER:", records);
    //     let count = 0;
    //     page.data.participant.gender.male = 0;
    //     page.data.participant.gender.female = 0;
    //     records.forEach(rec => {
    //         page.data.participant.gender[rec.gender] = rec.antall;
    //         count += rec.antall;
    //     });
    //     page.data.participant.count = count;
    //     create_gender_distribution_chart('gender-distribution', page.data.participant.gender);

    //     // XXX: trigger me..
    //     $('#participant-count').text(page.data.participant.count);
    // });

    // dk.on(tctrdash.ds.result_ds, 'fetch-data', function (ds, data) {
    //     $('#period').text(page.data.month);
    //     page.busy(false);

    //     create_tctr_filter(data.records);

    //     create_time_used_chart('time-used', data.records);
    //     create_pass_fail_chart('strykprosent', data.records);
    //     create_results_chart('resultat', data.records);
    //     create_results_per_tctr_chart('exams-per-tctr', data.records);

    //     // $('#result-data').empty();
    //     // let result_data = dk.DataTable('#result-data', {
    //     //     data: dk.data.DataSet.create({
    //     //         pagesize: max_records,
    //     //         datasource: dk.data.ArraySource.create({
    //     //             data: page.records
    //     //         })
    //     //     })
    //     // });
    // });


    // // connect page ui widgets
    // $('#navigate-month-prev').on('click', () => set_month(page.data.month.prev()));
    // $('#navigate-month-next').on('click', () => set_month(page.data.month.next()));

    // $('#rm-tctr-filter').on('click', function () {
    //     state.set_item('autnr', '');  // XXX state should have a delete_item
    //     delete page.filter.autnr;
    //     page.busy(true);
    //     ds.set_filter(page.filter);
    // });

    // $('.tctr-filter-box').on('click', '.tctr-filter', function () {
    //     $('.tctr-filter-box>.selected').removeClass('selected');
    //     page.busy(true);
    //     const autnr = $(this).data('autnr');
    //     set_autnr(autnr);
    //     $(this).addClass('selected');
    // });
    
    // // start page
    // page.filter.month = page.data.month.idtag();
    // if (page.data.autnr) page.filter.autnr = autnr;
    // ds.set_filter(page.filter);
});
