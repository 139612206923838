
import {TestcenterList} from "./tctr-datagrid";
import {TestleaderList, TestleaderListKKN} from "./tl-datagrid";
import Highcharts from 'highcharts';
import addMore from "highcharts/highcharts-more";
import addDrilldown from "highcharts/modules/drilldown";
import addNoData from "highcharts/modules/no-data-to-display";
import addExporting from "highcharts/modules/exporting";
addMore(Highcharts);
addDrilldown(Highcharts);
addNoData(Highcharts);
addExporting(Highcharts);

import BellCurve from 'highcharts/modules/histogram-bellcurve';
BellCurve(Highcharts);

import theme from "highcharts/themes/sand-signika";
theme(Highcharts);

import Exporting from 'highcharts/modules/exporting';
Exporting(Highcharts);

import {page} from "./page-ui";
import ttcal from "./ttcal";
import ds from "./datasources";
import create_exam_prep_chart from "./charts/exam-prep-chart";
import create_gender_distribution_chart from "./charts/gender-distribution-chart";
import create_time_used_chart from "./charts/create-time-used-chart";
import create_results_chart from "./charts/results-chart";
import create_pass_fail_chart from "./charts/pass-fail-chart";
import create_results_per_tctr_chart from "./charts/results-per-tctr-graph";
import { MainMenu } from './menu';
import "./document-ready";
import {AdminTestcenterList} from "./tctr-datagrid";
import {AdminTestleaderList} from "./tl-datagrid";


const tctrdash = {
    TestcenterList,
    TestleaderList,
    TestleaderListKKN,
    ttcal,
    create_exam_prep_chart,
    create_gender_distribution_chart,
    create_time_used_chart,
    create_results_chart,
    create_pass_fail_chart,
    create_results_per_tctr_chart,
    ds,
    MainMenu,
    AdminTestcenterList,
    AdminTestleaderList,
    page
    //Highcharts
};

export default tctrdash;
