
import Highcharts from 'highcharts';
import {page} from "../page-ui";


export default function create_pass_fail_chart(placement, records) {
    try {
        const pass_fail = {pass: 0, fail: 0};
        records.forEach(r => {
            if (r.passing_grade) {
                ++pass_fail.pass;
            } else {
                ++pass_fail.fail;
            }
        });
        pass_fail.tot = pass_fail.pass + pass_fail.fail;
        pass_fail.pass_pct = 0;
        pass_fail.fail_pct = 0;
    
    
        if (!page.charts.strykprosent) {
            page.charts.strykprosent = new Highcharts.chart(placement, {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: 0,
                },
                credits: false,
                title: {
                    text: 'Bestått<br>vs<br>ikke-bestått',
                    align: 'center',
                    verticalAlign: 'middle',
                    y: 50
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                },
                plotOptions: {
                    pie: {
                        dataLabels: {
                            enabled: true,
                            distance: -50,
                            style: {
                                //fontWeight: 'bold',
                                color: 'white'
                            }
                        },
                        startAngle: -90,
                        endAngle: 90,
                        center: ['50%', '75%'],
                        size: '100%'
                    }
                },
                series: [
                    {
                        type: 'pie',
                        name: 'prosent',
                        innerSize: '50%',
                        data: [
                            {
                                y: 0,
                                color: '#67da67'
                            },
                            {
                                y: 0,
                                color: 'red',
                            },
                        ]
                    }
                ]
            });
        }
        page.charts.strykprosent.hideLoading();
    
        if (pass_fail.tot > 0) {
            pass_fail.pass_pct = Math.round(100 * pass_fail.pass / pass_fail.tot, 1);
            pass_fail.fail_pct = Math.round(100 * pass_fail.fail / pass_fail.tot, 1);
            page.charts.strykprosent.series[0].data[0].update({
                name: `Bestått<br>${pass_fail.pass}`,
                y: pass_fail.pass,
            });
            setTimeout(() => {
                page.charts.strykprosent.series[0].data[1].update({
                    name: `Ikke bestått<br>${pass_fail.fail}`,
                    y: pass_fail.fail,
                });
            }, 500);
        }
        console.log('passfail:', pass_fail);
    } catch (e) {
        dk.error("Exception happended during create_pass_fail_chart:" + e);
    }
}
