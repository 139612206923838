
import Highcharts from 'highcharts';
import {page} from "../page-ui";


export default function create_results_per_tctr_chart(placement, records) {
    try {
        const tctr_count = {};
        records.forEach(r => tctr_count[r.autnr] = {pass: 0, fail: 0, total: 0, autnr: r.autnr});
        records.forEach(r => {
            if (r.passing_grade) {
                ++tctr_count[r.autnr].pass;
            } else {
                --tctr_count[r.autnr].fail;
            }
            ++tctr_count[r.autnr].total;
        });
    
        // tctr ordered by number of results
        const tctr = Object.values(tctr_count).map(r => r.autnr).sort((a, b) => tctr_count[a].total - tctr_count[b].total);
        dk.log("TCTR:", tctr.map(autnr => tctr_count[autnr]));
        // Age categories
        var categories = tctr;
        // console.log("PLACEMENT-HEIGHT:", $(`#${placement}`).height());
        // console.log("PLACEMENT-HEIGHT:SETTING:", (3 + tctr.length) * 19);
        $(`#${placement}`).height((3 + tctr.length) * 19);
        // console.info("CAT:", categories);
        // console.log("PLACEMENT-HEIGHT:", $(`#${placement}`).height());
    
        if (!page.charts.results_per_tctr) {
            page.charts.results_per_tctr = new Highcharts.chart(placement, {
                chart: {
                    type: 'bar'
                },
                title: {
                    text: null
                },
                credits: false,
                xAxis: [
                    {
                        categories: categories,
                        reversed: false,
                        labels: {
                            step: 1
                        }
                    }, { // mirror axis on right side
                        opposite: true,
                        reversed: false,
                        categories: categories,
                        linkedTo: 0,
                        labels: {
                            step: 1
                        }
                    }
                ],
                yAxis: {
                    title: {
                        text: null
                    },
                    labels: {
                        formatter: function () {
                            return Math.abs(this.value);
                        }
                    }
                },
            
                plotOptions: {
                    series: {
                        stacking: 'normal'
                    }
                },
            
                tooltip: {
                    formatter: function () {
                        return '<b>' + this.series.name + ', ' + this.point.category + '</b><br>' +
                               'antall: ' + Highcharts.numberFormat(Math.abs(this.point.y), 0);
                    }
                },
            
                series: [
                    {
                        name: 'ikke bestått',
                        data: tctr.map(autnr => tctr_count[autnr].fail)
                    }, {
                        name: 'bestått',
                        data: tctr.map(autnr => tctr_count[autnr].pass)
                    }
                ]
            });
        } else {
            page.charts.results_per_tctr.hideLoading();
            page.charts.results_per_tctr.xAxis[0].setCategories(categories);
            page.charts.results_per_tctr.xAxis[1].setCategories(categories);
            page.charts.results_per_tctr.series[0].setData(tctr.map(autnr => tctr_count[autnr].fail));
            page.charts.results_per_tctr.series[1].setData(tctr.map(autnr => tctr_count[autnr].pass));
        }
        page.charts.results_per_tctr.reflow();
    } catch (e) {
        dk.error("Exception happended during create_results_per_tctr_chart:" + e);
    }
}
