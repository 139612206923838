
import ttcal from "./ttcal";


function set_page_busy(b) {
    if (b) {
        $('#window-spinner').addClass('running');
    } else {
        $('#window-spinner').removeClass('running');
    }
    return b;
}


export const page = {
    state: new dk.web.state.storage.hash(),
    busy: set_page_busy,
    
    data: {
        // month: ttcal.Month.from_idtag(state.get_item('month', new ttcal.Month().idtag())),
        month: ttcal.Month.from_idtag(new ttcal.Month().idtag()),
        // autnr: state.get_item('autnr', null),
        autnr: null,

        participant: {
            gender: {
                male: 0,
                female: 0
            },
            count: 0
        }
    },
    filter: {},
    charts: {},
    ui: {
        widgets: {  // items?
            month: {
                next: null,
                prev: null
            },
            tctr_filter: {
                remove: {},
                container: {}
            },
            result_data: {}
        }
    }
};
