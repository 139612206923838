
import {page} from "./page-ui";

const max_records = 100000;
const ds = url => dk.data.DataSet.create({
    pagesize: max_records,
    datasource: url
});

const result_ds = ds('/api/results/');
const gender_ds = ds('/api/gender/');
const examprep_ds = ds('/api/examprep/');

function set_filter(filter) {
    Object.values(page.charts).forEach(chart => chart.showLoading());
    result_ds.set_filter(filter);
    gender_ds.set_filter(filter);
    examprep_ds.set_filter(filter);
}

export default {
    result_ds,
    gender_ds,
    examprep_ds,
    set_filter
};
