
import Highcharts from 'highcharts';
import {page} from "../page-ui";


export default function create_time_used_chart(placement, records) {
    try {
        let avg_time_used = 0;
        const time_used_histogram = records.map(r => r.duration_secs).filter(v => !!v).sort().map(v => Math.round(v / 60, 0));
        console.info("TIME:USED:HISTOGRAM:", time_used_histogram);
    
        if (time_used_histogram.length === 0) {
            if (page.charts.time_used) {
                page.charts.time_used.hideLoading();
                page.charts.time_used.series[0].update({binsNumber: 0});
                page.charts.time_used.series[1].setData([]);
            } else {
                dk(`#${placement}`).innerHTML = "Mangler data";
            }
            return;
        } else {
            if (time_used_histogram.length === 1) {
                avg_time_used = time_used_histogram[0];
            } else {
                avg_time_used = time_used_histogram.reduce((a, b) => a + b, 0) / time_used_histogram.length;
            }
        }
    
        if (!page.charts.time_used) {
            page.charts.time_used = Highcharts.chart(placement, {
                title: {
                    text: `tidsforbruk (minutter), snitt = ${Math.round(avg_time_used, 4)} minutter`
                },
                credits: false,
                xAxis: [
                    {
                        title: {text: 'gjennomføringer (data)'},
                        alignTicks: false
                    }, {
                        title: {text: 'bin-verdi (histogram)'},
                        alignTicks: false,
                        opposite: true
                    }
                ],
            
                yAxis: [
                    {
                        title: {text: 'minutter brukt (data)'}
                    }, {
                        title: {text: 'antall (histogram)'},
                        opposite: true
                    }
                ],
            
                series: [
                    {
                        name: 'Histogram',
                        type: 'histogram',
                        binsNumber: Math.min(20, Math.sqrt(records.length)),
                        xAxis: 1,
                        yAxis: 1,
                        baseSeries: 's1',
                        zIndex: -1
                    }, {
                        name: 'Data',
                        type: 'scatter',
                        data: time_used_histogram,
                        id: 's1',
                        marker: {
                            radius: 1.5
                        }
                    }
                ]
            });
        } else {
            page.charts.time_used.hideLoading();
            page.charts.time_used.series[0].update({binsNumber: Math.min(20, Math.sqrt(records.length))});
            page.charts.time_used.series[1].setData(time_used_histogram);
        }
    } catch (e) {
        dk.error("Exception happended during create_time_used_chart:" + e);
    }
}
