

class Month {
    constructor(year, month) {
        if (year === undefined && month === undefined) {
            const td = new Date();
            this.year = td.getFullYear();
            this.month = td.getMonth() + 1;
        } else {
            this.year = year;
            this.month = month;
        }
        if (!(1 <= this.month && this.month <= 12)) {
            throw "Month must be in 1..12.";
        }
        this.date = new Date(this.year, this.month - 1);
        this.name = this.date.toLocaleString('nb-no', {month: "long"});
        this.short_name = this.date.toLocaleString('nb-no', {month: "short"});
    }

    static from_idtag(tag) {
        const y = parseInt(tag.slice(1, 5), 10);
        const m = parseInt(tag.slice(5, 10), 10);
        return new Month(y, m);
    }


    toordinal() {
        return 12 * this.year + (this.month - 1);
    }

    /**
     * Returns -1 if a < b, 0 if a == b, +1 if a > b
     * @param a
     * @param b
     */
    static compare(a, b) {
        const diff = b.toordinal() - a.toordinal();
        if (diff === 0) return 0;
        return diff < 0 ? -1 : 1;
    }

    static min(a, b) {
        const cmp = Month.compare(a, b);
        if (cmp === 0) return a; // min returns the first if equal, max returns the second
        return cmp < 0 ? a : b;
    }

    static max(a, b) {
        const cmp = Month.compare(a, b);
        if (cmp === 0) return b; // cf. min
        return cmp < 0 ? a : b;
    }

    idtag() {
        return `m${this.year}${this.month}`;
    }

    toString() { return `${this.year} / ${this.month}`; }

    /**
     * Return a new month n months into the future.
     * @param n int
     */
    add(n) {
        let me = this.toordinal();
        me += n;
        const q = Math.floor(me / 12);
        const r = me % 12;
        return new Month(q, r + 1);
    }

    sub(n) {
        if (n instanceof Month) {
            // month - month => int (number of months between)
            const first = Month.min(this, n);
            const last = Month.max(this, n);
            const ydiff = last.year - first.year;
            const mdiff = last.month - first.month;
            const res = 12 * ydiff + mdiff;
            return Month.compare(this, n) >= 0 ? res : -res;
        }
        // month - int => month
        return this.add(-n);
    }

    prev() { return this.sub(1); }
    next() { return this.add(1); }


}


export default {
    Month
};
