
import Highcharts from 'highcharts';
import {page} from "../page-ui";


export default function create_gender_distribution_chart(placement, {male, female}) {
    try {
        dk.debug('gender-chart:', male, female);
        let chart = page.charts.gender_distribution;
    
        if (!chart) {
            page.charts.gender_distribution = Highcharts.chart(placement, {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'pie'
                },
                credits: false,
                title: {
                    text: null
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: true,
                            format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                            style: {
                                color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
                            }
                        }
                    }
                },
                series: [
                    {
                        name: 'Gender',
                        colorByPoint: true,
                        data: [
                            {
                                name: 'menn',
                                y: male,
                            }, {
                                name: 'kvinner',
                                y: female
                            }
                        ]
                    }
                ]
            });
        } else {
            chart.hideLoading();
            chart.series[0].data[0].update({y: male});
            chart.series[0].data[1].update({y: female});
        }
    } catch (e) {
        dk.error("Exception happended during create_gender_distribution_chart:" + e);
    }
}
